*, *::before, *::after {
  transition: all ease-out 0.3s;
}

p strong, p b {
  font-weight: 500;
}

.weight--300 {
  font-weight: 300;
}

.weight--500 {
  font-weight: 500;
}

.weight--800 {
  font-weight: 800;
}

.text--uppercase {
  text-transform: uppercase;
}

// Color

$light-color: #faf8e3;
$lighter-color: #f1f1e8;
$tertiary-color: #efb346;
$quarternary-color: #7501aa;

.color--white {
  color: $white;
}

a.color--white, .color--white a {
  color: $white;
}
a.color--white:hover, .color--white a:hover {
  color: scale-color($primary-color, $lightness: +80%);
} 

.color--primary {
  color: $primary-color;
}

.color--secondary {
  color: $secondary-color;
}

.color--tertiary {
  color: $tertiary-color;
}
  
.color--light {
  color: $light-color;
}

.color--lighter {
  color: $lighter-color;
}

.color--dark-gray {
  color: $dark-gray;
}

.bg-color--light {
  background: linear-gradient(145deg, $light-color, scale-color($light-color, $saturation: +25%, $lightness: +5%), scale-color($light-color, $saturation: +45%, $lightness: +7%));
  background-color: $light-color;
  border-color: darken($light-color, .5%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--lighter {
  background: linear-gradient(145deg, $lighter-color, scale-color($lighter-color, $saturation: +15%, $lightness: +1%), scale-color($lighter-color, $saturation: +25%, $lightness: +2%));
  background-color: $lighter-color;
  border-color: darken($lighter-color, .25%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--light-gray {
  background-color: $light-gray;
  border-top: 1px solid darken($light-gray, .5%);
  border-bottom: 1px solid darken($light-gray, .5%);
}

.bg-color--primary {
  background: linear-gradient(145deg, $primary-color, scale-color($primary-color, $saturation: +25%, $lightness: +5%), scale-color($primary-color, $saturation: +55%, $lightness: +9%));
  background-color: $primary-color;
  border-color: darken($primary-color, 1%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--secondary {
  background: linear-gradient(145deg, $secondary-color, scale-color($secondary-color, $saturation: +25%, $lightness: +5%), scale-color($secondary-color, $saturation: +45%, $lightness: +7%));
  background-color: $secondary-color;
  border-color: darken($secondary-color, 2%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--secondary-dark {
  background-color: darken($secondary-color, 3%);
  border-color: darken($secondary-color, 4%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--tertiary {
  background: linear-gradient(145deg, $tertiary-color, scale-color($tertiary-color, $saturation: +25%, $lightness: +5%), scale-color($tertiary-color, $saturation: +45%, $lightness: +7%));
  background-color: $tertiary-color;
  border-color: darken($tertiary-color, 1%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.bg-color--quarternary {
  background: linear-gradient(145deg, $quarternary-color, scale-color($quarternary-color, $saturation: +25%, $lightness: +5%), scale-color($quarternary-color, $saturation: +45%, $lightness: +7%));
  background-color: $quarternary-color;
  border-color: darken($quarternary-color, 1%);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}

.big-border--top {
  border-top-width: 12px;
}
.big-border--bottom {
  border-bottom-width: 12px;
}

.flex {
  display: flex;
}

// Well

.well {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.well--tiny {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.well--small {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.well--medium {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.well--large {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.well--top {
  margin-bottom: 0;
}
.well--bottom {
  margin-top: 0;
}

.well--dry {
  margin-top: 0;
  margin-bottom: 0;
}

@media print, screen and (max-width: 40em) { 
  .well {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .well--large {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .well--top {
    margin-bottom: 0;
  }
  .well--bottom {
    margin-top: 0;
  }
}

.stay-connected {
  background-image: url('/img/connect.jpg');
  background-size: cover;
  background-position: center;
  border: 1px solid scale-color($light-color, $saturation: -50%, $lightness: -4%);
}

.location {
  background-image: url('/img/location-light.jpg');
  background-size: cover;
  background-position: center;
  border: 1px solid scale-color($light-color, $saturation: -50%, $lightness: -2%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.location .button.hollow {
  border-color: $primary-color;
  margin-top: 0 !important;
}
.location .button.hollow:hover, .location .button.hollow:focus {
  background-color: $primary-color;
  color: $white;
}

address {
  font-size: 1.2em;
}

.hero {
  padding: 6.25rem 0 6rem 3rem;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.15)), url('/img/hero-bg-right.jpg');
  background-color: #f5f5e6;
  background-size: auto 700px;
  background-position: top right;
  background-repeat: no-repeat;
  border-bottom: 1px solid darken($primary-color, 5%);
}

.hero h2{
  font-size: 550%;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1.5rem;
}

@media print, screen and (max-width: 70em) { 
  .hero {
    background-size: auto 600px;
    background-position: top right;
    padding: 5.5rem 0 3.5rem 2rem;
  }
  .hero h2 {
    font-size: 450%;
  }
}

@media print, screen and (max-width: 55em) { 
  .hero {
    background-size: auto 600px;
    background-position: top right;
    padding: 5.5rem 0 3.5rem 2rem;
  }
  .hero h2 {
    font-size: 400%;
  }
}

@media print, screen and (max-width: 40em) { 
  .hero {
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.35)), url('/img/hero-bg-right.jpg');
    background-size: cover;
    background-position: top right;
    padding: 7rem 0 3.5rem 2rem;
  }
  .hero h2 {
    font-size: 350%;
  }
}

.hero img{
  max-width: 40%;
  margin-bottom: 1em;
}

.reveal {
  border: 0;
  border-top: 12px solid $primary-color;
}

hr {
  border-top: 2px solid $white;
  border-bottom: none;
  width: 10em;
}

.get-started {
	position: absolute;
	top: 10px;
	right: 20px;
}

.radius {
  border-radius: 5px;
}

.text--normal {
  font-weight: 300;
}

.text--large {
  font-size: 1.8em;
}

.text--medium {
  font-size: 1.2em;
}

.text--indent {
  padding-left: 2em;
}

.text--underline {
  text-decoration: underline;
}

.text--inline {
  display: inline;
}

#schedule p {
  font-size: 1.125em;
}

#schedule p.text--small {
  font-size: .925em;
}

footer .text--large {
  font-size: 1.7em;
}

.about .text--large {
  font-size: 1.5em;
}

footer .menu li::after {
  content: "\2022";
  margin: 0 1rem;
  font-weight: 500;
  font-size: 1.2em;
}
footer .menu li:last-of-type::after {
  content: "\00a0";
  margin: 0;
}

footer .menu li a {
  padding: .5rem;
  margin: 0;
}

footer .menu li a:hover {
  color: scale-color($color: $secondary-color, $saturation: 0%, $lightness: 70%, $alpha: 1.0);
}

.text--small {
  font-size: .9em;
}

.stick-to-top {
  position: absolute;
  width: 100%;
}

.title-bar {
  padding: 0.75rem;
  background: rgba($white, .95);
  color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px #42424242;
}

.top-bar.color--white a {
  color: $white;
}

.top-bar.color--white .button.hollow, .color--white.button.hollow {
  border: 1px solid $white;
  color: $white;
}

.top-bar.color--white .button.hollow:hover, .top-bar.color--white .button.hollow:focus, .color--white.button.hollow:hover, .color--white.button.hollow:focus {
  border-color: #01aa9d;
  color: #01aa9d;
  background-color: $white;
}

.title-bar-title {
  cursor: pointer;
}

.title-bar h1 {
  font-size: 90%;
  margin: 0;
}

.menu-icon::after {
  background: $primary-color;
  box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
}

.top-bar, .top-bar ul {
  background-color: transparent;
  font-size: 1.05em;
}

@media print, screen and (max-width: 40em) { 
  .top-bar, .top-bar ul {
    background-color: $primary-color;
    font-size: 1.05em;
  }
  .menu a, .menu .button {
    color: $white;
  }
}

@media print, screen and (max-width: 40em) { 
  .top-bar {
    border-bottom: 1px solid darken($primary-color, 5%);
    box-shadow: 0 6px 6px #42424242;
  }
}

/*
.menu a, .menu .button {
    color: #fff;
}
*/

.menu a:hover {
  color: rgba(157, 240, 233, 0.83);
}

.pricing {
  background: linear-gradient(145deg, rgba($primary-color, 0.85), rgba($primary-color, 0.15)), url('/img/pricing-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.pricing .text--large {
  font-size: 1.25em;
  color: #ffffff;
  text-shadow: 0 0 8px rgba(darken($primary-color, 5%), 0.75);
}
.pricing p.text--large em{
  color: scale-color($primary-color, $lightness: 90%);
  font-weight: 500;
  font-style: normal;
  @include breakpoint(medium down) {
    font-size: 1.1em;
  }
}

@media print, screen and (max-width: 40em) { 
  .pricing {
    padding-top: 3rem;
  }
}

.pricing .card-divider {
  padding-bottom: 0;
}

.pricing ul {
  margin: 0;
}
.pricing li {
  list-style-type: none;
  margin: 0;
  text-align: center;
}
.pricing li a {
  text-decoration: underline;
  color: $body-font-color;
}

.card h3 {
  font-size: 1.5em;
  padding-bottom: .25rem;
}

.card h4 {
  line-height: .55;
  font-weight: 300;
}

.card h3 + h4 {
  font-size: 100%;
  margin-bottom: 1rem;
}

.card h4 + h6 {
  font-size: 80%;
  font-weight: 300;
  text-transform: uppercase;
}

.card-section {
  border: none;
  padding: 1.5rem 1.125rem 0;
}

.card {
  border: 0;
  font-size: 105%;
  border-top: 12px solid $primary-color;
  overflow: visible;
}

.about .card {
  margin-bottom: 1.45rem;
}

.card table {
  margin-bottom: 1.5rem !important;
}

.card.first {
  border-top: 12px solid $quarternary-color;
}
.card.second {
  border-top: 12px solid $secondary-color;
}
.card.third {
  border-top: 12px solid $tertiary-color;
}

.card hr {
  border-top: 1px solid rgba(#000, .2);
  border-bottom: 1px solid rgba(#000, .05);
  width: 100%;
}

.card-bottom {
  padding-top: 1rem;
  padding-bottom: 1.75rem;
}

.new .card-section {
  border: none;
  padding: 1.5rem 1.125rem;
  font-size: 1.1em;
}

.new .card-section a {
  font-weight: 500;
}

.new .card-bottom {
  padding: 0 1.25rem 1.125rem;
}

.new .text--large {
  font-size: 1.25em;
  color: #ffffff;
  text-shadow: 0 0 8px rgba(darken($primary-color, 5%), 0.75);
}

.faq {
  background: linear-gradient(145deg, rgba($primary-color, 0.5), rgba($primary-color, 0)), url('/img/faq-blue-bg.jpg');
  background-size: cover;
  background-position: top center;
  padding: 3em 1em 2em;
  border-bottom: 12px solid $primary-color;
}
@include breakpoint(small only) {
  .faq {
    padding: 3.5em 1em 0;
  }
}

@include breakpoint(large) {
  .faqs {
    margin: 3rem 0;
  }
}

.faq .text--large {
  font-size: 1.25em;
}

.faq hr {
  border-top: 8px solid scale-color($primary-color, $lightness: 10%);
  border-bottom: none;
  width: 22em;
  margin-left: 0;
}

.faqs hr {
  border-top: 4px solid $primary-color;
  border-bottom: none;
  width: 10em;
  margin-left: 0;
}

.faqs .column {
  background: scale-color($light-color, $saturation: -20%, $lightness: 80%);
  padding: 1.5em;
}

.faqs .column p:last-of-type {
  margin-bottom: 0;
}

.faqs .column:nth-of-type(4n+1) hr {
  border-top: 4px solid $secondary-color;
  border-bottom: none;
  width: 10em;
  margin-left: 0;
}

.faqs .column:nth-of-type(4n+2) hr {
  border-top: 4px solid $tertiary-color;
  border-bottom: none;
  width: 10em;
  margin-left: 0;
}

.faqs .column:nth-of-type(4n+3) hr {
  border-top: 4px solid $quarternary-color;
  border-bottom: none;
  width: 10em;
  margin-left: 0;
}

.faqs .column {
  background: scale-color($light-color, $saturation: -20%, $lightness: 80%);
  padding: 1.5em;
}
.faqs .column:nth-of-type(4n+1) {
  background: scale-color($light-color, $saturation: -20%, $lightness: 60%);
}
.faqs .column:nth-of-type(4n+2) {
  background: scale-color($light-color, $saturation: -20%, $lightness: 80%);
}
.faqs .column:nth-of-type(4n+3) {
  background: scale-color($light-color, $saturation: -20%, $lightness: 60%);
}

@include breakpoint(medium only) {
  .faqs .column:nth-of-type(4n+1) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 60%);
  }
  .faqs .column:nth-of-type(4n+2) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 90%);
  }
  .faqs .column:nth-of-type(4n+3) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 80%);
  }
  .faqs .column {
    background: scale-color($light-color, $saturation: -20%, $lightness: 70%);
    padding: 1.5em;
  }
}

@include breakpoint(small only) {
  .faqs .column:nth-of-type(4n+1) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 60%);
  }
  .faqs .column:nth-of-type(4n+2) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 90%);
  }
  .faqs .column:nth-of-type(4n+3) {
    background: scale-color($light-color, $saturation: -20%, $lightness: 70%);
  }
  .faqs .column {
    background: scale-color($light-color, $saturation: -20%, $lightness: 90%);
    padding: 1.5em;
  }
}

.faqs h5 {
  font-weight: 500;
}

.has-wings::before, .has-wings::after {
  content: "—";
  display: inline-block;
  margin: 0px 0.5em;
}

.membership .shrink {
  max-width: 32em;
}

.membership hr {
  border-top: 6px solid scale-color($primary-color, $lightness: 10%);
  border-bottom: none;
  width: 12em;
}

.reveal hr {
  border-top: 6px solid scale-color($primary-color, $lightness: 10%);
  border-bottom: none;
  width: 12em;
  margin-top: 1rem;
}

.about .shrink {
  max-width: 34em;
}

.about .shrink p {
  font-size: 1.2em;
  line-height: 1.75;
}

.about .shrink p.text--large {
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.5;
}

.about hr {
  border-top: 4px solid $primary-color;
  border-bottom: none;
  width: 10em;
  margin-left: 0;
}

.about hr.color--white {
  border-top: 2px solid $white;
  border-bottom: none;
  width: 6em;
  margin-top: 1rem;
  margin-left: auto;
  margin-bottom: 2rem;
}

.about .card-section {
  padding: 1.5rem 1.125rem;
}

@include breakpoint(small only) {
  .about .well--large {
    margin-top: 5.5rem;
    margin-bottom: 2.5rem;
  }
  .new .well--large {
    margin-top: 5.5rem;
    margin-bottom: 2.5rem;
  }
}

.about .thumb-wrap {
  position: relative;
  margin-bottom: 3.5rem;
}

.about .thumbnail {
  max-width: 9rem;
  border-radius: 100%;
  border: 5px solid $white;
  box-shadow: 0 0 8px #42424282;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: -4.75rem;
  left: calc( 50% - 4.5rem);
}

.about .card h3 {
  line-height: 1.1;
}

.about .card h3 + h4 {
  font-size: 70%;
}

.about-bg {
  background: linear-gradient(75deg, rgba($lighter-color, 0.25), rgba($lighter-color, 0.15), rgba($lighter-color, 0.85)), url('/img/about-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
}

.text-center th {
  text-align: center;
}

td {
  border: 1px solid #eaeaea;
}

th {
  color: $white;
  background: $primary-color;
  border: 1px solid darken($primary-color, 2.5%);
}

// Button Customs

.button.white {
  background-color: transparent;
  border: 1px solid #fff;
}
.button.white:hover {
  color: $white;
  background-color: $primary-color;
  border: 1px solid $primary-color;
}

.button {
  transition: all .2s ease-in-out;
}

.button:hover, .button:focus {
  background-color: lighten($primary-color, 2.5%);
  transform: scale(1.05);
}

.button.secondary {
  color: $white;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #ea2323;
  color: $white;
}

.top-bar .button.white, .top-bar .button.white:hover {
  border-width: 1px;
}

.top-bar .button {
  margin: 0 1em;
  font-size: .95em;
}

.button.hollow:hover, .button.hollow:focus {
    border-color: $primary-color;
    color: $white;
    background-color: $primary-color;
}

@media print, screen and (max-width: 40em) { 
  .button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover, .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
    border: 1px solid $white;
    margin: .65em 0 1em !important;
  }
  .button.hollow:hover, .button.hollow:focus {
    border: 1px solid $white;
    background-color: $white;
    color: $primary-color;
  }
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.row.full-width {
  width: 100%;
  max-width: 100%; 
}